export default {
  path: '/groups',
  name: 'groups',
  redirect: { name: 'groups.list' },
  component: () =>
    import(/* webpackChunkName: "settings" */ '@/views/Home/Groups'),

  children: [
    {
      path: '',
      name: 'groups.list',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/Home/Groups/Groups'),
    },
  ],
}
