export default {
  path: '/social-links',
  name: 'social-links',
  redirect: { name: 'social.links' },
  component: () =>
    import(/* webpackChunkName: "social-links" */ '@/views/Home/SocialLinks'),

  children: [
    {
      path: '',
      name: 'social.links',
      component: () =>
        import(
          /* webpackChunkName: "social-links-list" */ '@/views/Home/SocialLinks/List'
        ),
    },
  ],
}
